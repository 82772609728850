'use client';

import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePathname } from 'next/navigation';
import { getCookie } from '@/lib/helpers/cookie';
import { CookieKey } from '@/lib/enums/cookieKey.enum';
import { useToaster } from '@/components/hooks/useToaster';
import { initIpLogin } from '@/lib/sso';
import { ToastKey } from '@/lib/enums/toastKey.enum';

const IpAccessDetector: React.FC = () => {
  const { t } = useTranslation();
  const pathname = usePathname();
  const { openToast, closeToast } = useToaster();
  const [ipAccess, setIpAccess] = useState<string | null>(null);

  const openLoginToast = (name: string) => {
    openToast({
      id: ToastKey.ip_access,
      text: t('toaster.ip', { name }),
      intent: 'default',
      buttons: [
        {
          title: t('toaster.login'),
          onClick: () => initIpLogin(),
        },
      ],
    });
  };

  const closeLoginToast = () => {
    closeToast({ id: ToastKey.ip_access });
  };

  useEffect(() => {
    const availableIpAccess = getCookie(CookieKey.ip_access);
    if (availableIpAccess !== ipAccess) {
      setIpAccess(availableIpAccess);
      if (availableIpAccess) {
        openLoginToast(availableIpAccess);
      } else {
        closeLoginToast();
      }
    }
  }, [pathname]);

  return null;
};

export default IpAccessDetector;
