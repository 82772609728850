import { Locale } from '@/lib/enums/locale.enum';

export type FetchOptions = {
  method?: 'GET' | 'PUT' | 'DELETE' | 'POST' | 'PATCH' | 'OPTIONS';
  headers?: Record<string, string>;
  body?: object;
  params?: Record<string, string | string[] | undefined>;
  bearer?: string | null;
  apiKey?: string;
  credentials?: RequestCredentials;
  revalidate?: number;
  timeout?: number;
  hideChannel?: boolean;
  locale?: Locale;
  cache?: RequestCache;
};

const prepareHeaders = (options?: FetchOptions) => {
  const requestHeaders = new Headers();
  requestHeaders.set('accept', 'application/json');
  requestHeaders.set('Content-Type', 'application/json');
  requestHeaders.set('User-Agent', process.env.USER_AGENT!);
  if (options?.bearer) {
    requestHeaders.set('Authorization', `Bearer ${options?.bearer}`);
  }
  if (options?.apiKey) {
    requestHeaders.set('api-key', options.apiKey);
  }
  Object.entries(options?.headers || {}).forEach(([key, val]) => {
    requestHeaders.set(key, val);
  });
  return requestHeaders;
};

const prepareUrl = (url: string, options?: FetchOptions) => {
  const requestUrl = new URL(url);
  Object.entries(options?.params || {}).forEach(([key, val]) => {
    if (val) {
      if (Array.isArray(val) && options?.params) {
        ((options.params[key] as string[]) || []).forEach((val: string) => {
          requestUrl.searchParams.append(key, val);
        });
      } else {
        requestUrl.searchParams.append(key, val as string);
      }
    }
  });
  return requestUrl;
};

const prepareRevalidate = (options?: FetchOptions) => {
  return Number.isInteger(options?.revalidate) ? options!.revalidate : 120;
};

const getBody = async <T>(response: Response) => {
  try {
    return (await response.json()) as T;
  } catch {
    return null;
  }
};

const defaultTimeout = parseInt(process.env.NEXT_PUBLIC_API_REQUEST_TIMEOUT!, 10);

export const fetchData = async <T>(url: string, options?: FetchOptions): Promise<T | null> => {
  const requestHeaders = prepareHeaders(options);
  const requestUrl = prepareUrl(url, options);

  try {
    const response = await fetch(requestUrl, {
      method: options?.method,
      headers: requestHeaders,
      body: options?.body ? JSON.stringify(options?.body) : undefined,
      credentials: options?.credentials,
      signal: AbortSignal.timeout(options?.timeout || defaultTimeout),
      next: {
        revalidate: options?.revalidate ? prepareRevalidate(options) : 0,
      },
      cache: options?.cache,
    });

    const result = await getBody<T>(response);
    if (!response.ok) {
      // eslint-disable-next-line
      console.error('request failed', {
        url: requestUrl.toString(),
        responseOk: response.ok,
        responseStatus: response.status,
        responseHeaders: Object.fromEntries(response.headers),
        responseBody: result,
      });
      return null as T;
    }
    return result;
  } catch (e) {
    // eslint-disable-next-line
    console.error('request failed', {
      url: requestUrl.toString(),
      e,
    });
    if ((e as { name: string }).name === 'TimeoutError') {
      // eslint-disable-next-line
      console.error('\n Request exceeded timeout. Aborted! \n');
      return null as T;
    }
    return null as T;
  }
};
