'use client';

import * as React from 'react';
import classNames from 'classnames';
import OverlayTitle from '@/components/ui/LayoutOverlay/OverlayTitle';
import Block, { IBlockProps } from '@/components/ui/Block';
import styles from './styles.module.scss';

export interface ILayoutOverlayProps {
  /** className to append */
  className?: string;
  /** Container size */
  size?: 'default' | 'max' | 'wide' | 'medium' | 'small' | 'mobilemenu' | 'filter';
  /** Children to render */
  children?: React.ReactNode;
  /** Title */
  title?: React.ReactNode;
  /** Title theme */
  titleTheme?: IBlockProps['theme'];
  /** Close event */
  onClose?: () => void;
  /** Go back in stack */
  goBack?: () => void;
  /** Footer */
  footer?: React.ReactNode;
}

/**
 * Main wrapper for overlay content.
 */
const LayoutOverlay: React.FunctionComponent<ILayoutOverlayProps> = (props) => {
  return (
    <div
      className={classNames(
        styles['layout-overlay'],
        {
          [styles['size-default']]: props.size === 'default' || !props.size,
          [styles['size-max']]: props.size === 'max',
          [styles['size-wide']]: props.size === 'wide',
          [styles['size-medium']]: props.size === 'medium',
          [styles['size-small']]: props.size === 'small',
          [styles['size-mobilemenu']]: props.size === 'mobilemenu',
          [styles['size-filter']]: props.size === 'filter',
        },
        props.className,
      )}
    >
      {props.title && props.onClose && (
        <Block
          className={styles['layout-overlay__title']}
          theme={props.titleTheme || 'lightest'}
          width="full"
        >
          <OverlayTitle
            title={props.title}
            onClose={props.onClose}
            goBack={props.goBack}
          />
        </Block>
      )}
      <div className={styles['layout-overlay__inner']}>
        <div className={classNames(styles['layout-overlay__content'])}>{props.children}</div>
      </div>
      {props.footer && <div className={styles['layout-overlay__footer']}>{props.footer}</div>}
    </div>
  );
};

LayoutOverlay.displayName = 'LayoutOverlay';

export default LayoutOverlay;
