'use client';

import * as React from 'react';
import classNames from 'classnames';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Portal } from '@/components/ui/Portal';
import styles from './styles.module.scss';

const OVERLAY = styles['overlay'];
const OVERLAY_BACKDROP = styles['overlay__backdrop'];
const OVERLAY_CONTENT = styles['overlay__content'];
const OVERLAY_INLINE = styles['overlay-inline'];
const OVERLAY_OPEN = styles['overlay-open'];
const OVERLAY_START_FOCUS_TRAP = styles['overlay-start-focus-trap'];
const OVERLAY_END_FOCUS_TRAP = styles['overlay-end-focus-trap'];

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/ban-types
function isFunction(value: any): value is Function {
  return typeof value === 'function';
}

function getActiveElement(element?: HTMLElement | null, options?: GetRootNodeOptions) {
  if (element == null) {
    return document.activeElement;
  }

  const rootNode = (element.getRootNode(options) ?? document) as DocumentOrShadowRoot & Node;
  return rootNode.activeElement;
}

export interface OverlayableProps extends OverlayLifecycleProps {
  autoFocus?: boolean;
  canEscapeKeyClose?: boolean;
  enforceFocus?: boolean;
  lazy?: boolean;
  shouldReturnFocusOnClose?: boolean;
  transitionDuration?: number;
  usePortal?: boolean;
  portalClassName?: string;
  portalContainer?: HTMLElement;
  portalStopPropagationEvents?: Array<keyof HTMLElementEventMap>;
  onClose?: (event: React.SyntheticEvent<HTMLElement>) => void;
}

export interface OverlayLifecycleProps {
  onClosing?: (node: HTMLElement) => void;
  onClosed?: (node: HTMLElement) => void;
  onOpening?: (node: HTMLElement) => void;
  onOpened?: (node: HTMLElement) => void;
}

export interface BackdropProps {
  backdropClassName?: string;
  backdropProps?: React.HTMLProps<HTMLDivElement>;
  canOutsideClickClose?: boolean;
  hasBackdrop?: boolean;
}

export interface OverlayProps extends OverlayableProps, BackdropProps {
  className?: string;
  children?: React.ReactNode;
  isOpen: boolean;
  transitionName?: string;
}

export interface OverlayState {
  hasEverOpened?: boolean;
}

export class Overlay extends React.PureComponent<OverlayProps, OverlayState> {
  public static displayName = `Overlay`;

  public static defaultProps: OverlayProps = {
    autoFocus: true,
    backdropProps: {},
    canEscapeKeyClose: true,
    canOutsideClickClose: true,
    enforceFocus: true,
    hasBackdrop: true,
    isOpen: false,
    lazy: true,
    shouldReturnFocusOnClose: true,
    transitionDuration: 300,
    transitionName: 'overlay',
    usePortal: true,
  };

  public static getDerivedStateFromProps({ isOpen: hasEverOpened }: OverlayProps) {
    if (hasEverOpened) {
      return { hasEverOpened };
    }
    return null;
  }

  private static openStack: Overlay[] = [];

  private static getLastOpened = () => Overlay.openStack[Overlay.openStack.length - 1];

  private isAutoFocusing = false;

  private lastActiveElementBeforeOpened: Element | null | undefined;

  public state: OverlayState = {
    hasEverOpened: this.props.isOpen,
  };

  public containerElement = React.createRef<HTMLDivElement>();

  private startFocusTrapElement = React.createRef<HTMLDivElement>();

  private endFocusTrapElement = React.createRef<HTMLDivElement>();

  public render() {
    if (this.props.lazy && !this.state.hasEverOpened) {
      return null;
    }

    const { className, autoFocus, children, enforceFocus, usePortal, isOpen } = this.props;

    const childrenWithTransitions = isOpen ? React.Children.map(children, this.maybeRenderChild) ?? [] : [];

    const maybeBackdrop = this.maybeRenderBackdrop();
    if (maybeBackdrop !== null) {
      childrenWithTransitions.unshift(maybeBackdrop);
    }
    if (isOpen && (autoFocus || enforceFocus) && childrenWithTransitions.length > 0) {
      childrenWithTransitions.unshift(
        this.renderDummyElement('__start', {
          className: OVERLAY_START_FOCUS_TRAP,
          onFocus: this.handleStartFocusTrapElementFocus,
          onKeyDown: this.handleStartFocusTrapElementKeyDown,
          ref: this.startFocusTrapElement,
        }),
      );
      if (enforceFocus) {
        childrenWithTransitions.push(
          this.renderDummyElement('__end', {
            className: OVERLAY_END_FOCUS_TRAP,
            onFocus: this.handleEndFocusTrapElementFocus,
            ref: this.endFocusTrapElement,
          }),
        );
      }
    }

    const containerClasses = classNames(
      OVERLAY,
      {
        [OVERLAY_OPEN]: isOpen,
        [OVERLAY_INLINE]: !usePortal,
      },
      className,
    );

    const transitionGroup = (
      <div
        aria-live="polite"
        className={containerClasses}
        onKeyDown={this.handleKeyDown}
        ref={this.containerElement}
      >
        <TransitionGroup
          appear={true}
          component={null}
        >
          {childrenWithTransitions}
        </TransitionGroup>
      </div>
    );
    if (usePortal) {
      return (
        <Portal
          className={this.props.portalClassName}
          container={this.props.portalContainer}
          stopPropagationEvents={this.props.portalStopPropagationEvents}
        >
          {transitionGroup}
        </Portal>
      );
    } else {
      return transitionGroup;
    }
  }

  public componentDidMount() {
    if (this.props.isOpen) {
      this.overlayWillOpen();
    }
  }

  public componentDidUpdate(prevProps: OverlayProps) {
    if (prevProps.isOpen && !this.props.isOpen) {
      this.overlayWillClose();
    } else if (!prevProps.isOpen && this.props.isOpen) {
      this.overlayWillOpen();
    }
  }

  public componentWillUnmount() {
    this.overlayWillClose();
  }

  public bringFocusInsideOverlay() {
    return window.requestAnimationFrame(() => {
      const activeElement = getActiveElement(this.containerElement.current);

      if (this.containerElement.current == null || activeElement == null || !this.props.isOpen) {
        return;
      }

      const container = this.containerElement.current;
      const isFocusOutsideModal = !container.contains(activeElement);
      if (isFocusOutsideModal) {
        this.startFocusTrapElement.current?.focus({ preventScroll: true });
        this.isAutoFocusing = false;
      }
    });
  }

  private maybeRenderChild = (child?: React.ReactNode) => {
    if (isFunction(child)) {
      // @ts-expect-error - as is from blueprint
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      child = child();
    }

    if (child == null) {
      return null;
    }

    const tabIndex = this.props.enforceFocus || this.props.autoFocus ? 0 : undefined;
    const decoratedChild =
      typeof child === 'object' ? (
        React.cloneElement(child as React.ReactElement, {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
          className: classNames((child as React.ReactElement).props.className, OVERLAY_CONTENT),
          tabIndex,
        })
      ) : (
        <span
          className={OVERLAY_CONTENT}
          tabIndex={tabIndex}
        >
          {child}
        </span>
      );

    const { onOpening, onOpened, onClosing, transitionDuration, transitionName } = this.props;

    return (
      <CSSTransition
        classNames={transitionName}
        onEntering={onOpening}
        onEntered={onOpened}
        onExiting={onClosing}
        onExited={this.handleTransitionExited}
        timeout={transitionDuration}
        addEndListener={this.handleTransitionAddEnd}
      >
        {decoratedChild}
      </CSSTransition>
    );
  };

  private maybeRenderBackdrop() {
    const { backdropClassName, backdropProps, hasBackdrop, isOpen, transitionDuration, transitionName } = this.props;

    if (hasBackdrop && isOpen) {
      return (
        <CSSTransition
          classNames={transitionName}
          key="__backdrop"
          timeout={transitionDuration}
          addEndListener={this.handleTransitionAddEnd}
        >
          <div
            {...backdropProps}
            className={classNames(OVERLAY_BACKDROP, backdropClassName, backdropProps?.className)}
            onMouseDown={this.handleBackdropMouseDown}
          />
        </CSSTransition>
      );
    } else {
      return null;
    }
  }

  private renderDummyElement(key: string, props: React.HTMLAttributes<HTMLDivElement> & { ref?: React.Ref<HTMLDivElement> }) {
    const { transitionDuration, transitionName } = this.props;
    return (
      <CSSTransition
        classNames={transitionName}
        key={key}
        addEndListener={this.handleTransitionAddEnd}
        timeout={transitionDuration}
        unmountOnExit={true}
      >
        <div
          tabIndex={0}
          {...props}
        />
      </CSSTransition>
    );
  }

  private handleStartFocusTrapElementFocus = (e: React.FocusEvent<HTMLDivElement>) => {
    if (!this.props.enforceFocus || this.isAutoFocusing) {
      return;
    }
    if (
      e.relatedTarget != null &&
      this.containerElement.current?.contains(e.relatedTarget as Element) &&
      e.relatedTarget !== this.endFocusTrapElement.current
    ) {
      this.endFocusTrapElement.current?.focus({ preventScroll: true });
    }
  };

  private handleStartFocusTrapElementKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (!this.props.enforceFocus) {
      return;
    }
    if (e.shiftKey && e.key === 'Tab') {
      const lastFocusableElement = this.getKeyboardFocusableElements().pop();
      if (lastFocusableElement != null) {
        lastFocusableElement.focus();
      } else {
        this.endFocusTrapElement.current?.focus({ preventScroll: true });
      }
    }
  };

  private handleEndFocusTrapElementFocus = (e: React.FocusEvent<HTMLDivElement>) => {
    if (
      e.relatedTarget != null &&
      this.containerElement.current?.contains(e.relatedTarget as Element) &&
      e.relatedTarget !== this.startFocusTrapElement.current
    ) {
      const firstFocusableElement = this.getKeyboardFocusableElements().shift();
      if (!this.isAutoFocusing && firstFocusableElement != null && firstFocusableElement !== e.relatedTarget) {
        firstFocusableElement.focus();
      } else {
        this.startFocusTrapElement.current?.focus({ preventScroll: true });
      }
    } else {
      const lastFocusableElement = this.getKeyboardFocusableElements().pop();
      if (lastFocusableElement != null) {
        lastFocusableElement.focus();
      } else {
        this.startFocusTrapElement.current?.focus({ preventScroll: true });
      }
    }
  };

  private getKeyboardFocusableElements() {
    const str = [
      'a[href]:not([tabindex="-1"]), button:not([disabled]):not([tabindex="-1"])',
      'details:not([tabindex="-1"])',
      'input:not([disabled]):not([tabindex="-1"])',
      'select:not([disabled]):not([tabindex="-1"])',
      'textarea:not([disabled]):not([tabindex="-1"])',
      '[tabindex]:not([tabindex="-1"])',
    ].join(',');
    const focusableElements: HTMLElement[] =
      this.containerElement.current !== null ? Array.from(this.containerElement.current.querySelectorAll(str)) : [];

    return focusableElements.filter(
      (el) => !el.classList.contains(OVERLAY_START_FOCUS_TRAP) && !el.classList.contains(OVERLAY_END_FOCUS_TRAP),
    );
  }

  private overlayWillClose() {
    document.removeEventListener('focus', this.handleDocumentFocus, /* useCapture */ true);
    document.removeEventListener('mousedown', this.handleDocumentClick);

    const { openStack } = Overlay;
    const stackIndex = openStack.indexOf(this);
    if (stackIndex !== -1) {
      openStack.splice(stackIndex, 1);
      if (openStack.length > 0) {
        const lastOpenedOverlay = Overlay.getLastOpened();
        if (lastOpenedOverlay.props.autoFocus && lastOpenedOverlay.props.enforceFocus) {
          lastOpenedOverlay.bringFocusInsideOverlay();
          document.addEventListener('focus', lastOpenedOverlay.handleDocumentFocus, /* useCapture */ true);
        }
      }

      if (openStack.filter((o) => o.props.usePortal && o.props.hasBackdrop).length === 0) {
        document.body.classList.remove(OVERLAY_OPEN);
      }
    }
  }

  private overlayWillOpen() {
    const { getLastOpened, openStack } = Overlay;
    if (openStack.length > 0) {
      document.removeEventListener('focus', getLastOpened().handleDocumentFocus, /* useCapture */ true);
    }
    openStack.push(this);

    if (this.props.autoFocus) {
      this.isAutoFocusing = true;
      this.bringFocusInsideOverlay();
    }

    if (this.props.enforceFocus) {
      document.addEventListener('focus', this.handleDocumentFocus, /* useCapture */ true);
    }

    if (this.props.canOutsideClickClose && !this.props.hasBackdrop) {
      document.addEventListener('mousedown', this.handleDocumentClick);
    }

    if (this.props.hasBackdrop && this.props.usePortal) {
      document.body.classList.add(OVERLAY_OPEN);
    }

    this.lastActiveElementBeforeOpened = getActiveElement(this.containerElement.current);
  }

  private handleTransitionExited = (node: HTMLElement) => {
    if (this.props.shouldReturnFocusOnClose && this.lastActiveElementBeforeOpened instanceof HTMLElement) {
      this.lastActiveElementBeforeOpened.focus();
    }
    this.props.onClosed?.(node);
  };

  private handleBackdropMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    const { backdropProps, canOutsideClickClose, enforceFocus, onClose } = this.props;
    if (canOutsideClickClose) {
      onClose?.(e);
    }
    if (enforceFocus) {
      this.bringFocusInsideOverlay();
    }
    backdropProps?.onMouseDown?.(e);
  };

  private handleDocumentClick = (e: MouseEvent) => {
    const { canOutsideClickClose, isOpen, onClose } = this.props;
    const eventTarget = (e.composed ? e.composedPath()[0] : e.target) as HTMLElement;

    const stackIndex = Overlay.openStack.indexOf(this);
    const isClickInThisOverlayOrDescendant = Overlay.openStack.slice(stackIndex).some(({ containerElement: elem }) => {
      return elem.current?.contains(eventTarget) && !elem.current.isSameNode(eventTarget);
    });

    if (isOpen && !isClickInThisOverlayOrDescendant && canOutsideClickClose) {
      onClose?.(e as never);
    }
  };

  private handleDocumentFocus = (e: FocusEvent) => {
    const eventTarget = e.composed ? e.composedPath()[0] : e.target;
    if (
      this.props.enforceFocus &&
      this.containerElement.current != null &&
      eventTarget instanceof Node &&
      !this.containerElement.current.contains(eventTarget as HTMLElement)
    ) {
      e.preventDefault();
      e.stopImmediatePropagation();
      this.bringFocusInsideOverlay();
    }
  };

  private handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    const { canEscapeKeyClose, onClose } = this.props;
    if (e.key === 'Escape' && canEscapeKeyClose) {
      onClose?.(e);
      e.stopPropagation();
      e.preventDefault();
    }
  };

  private handleTransitionAddEnd = () => {
    // no-op
  };
}
