type EasingOptions = 'linear' | 'ease-in' | 'ease-out' | 'ease-in-out';

export interface IScrollToCustomOptions extends ScrollToOptions {
  duration?: number;
  easing?: EasingOptions;
}

export function scrollTo(el: Element | Window, options?: IScrollToCustomOptions) {
  if (!(el instanceof Element) && !(el instanceof Window)) {
    throw new Error(`element passed to scrollTo() must be either the window or a DOM element, you passed something else!`);
  }

  if (el instanceof Window) {
    window.scrollTo({
      ...options,
      behavior: 'smooth',
    });
  }
  if (el instanceof Element) {
    el.scrollTo({
      ...options,
      behavior: 'smooth',
    });
  }
}
