'use client';

import * as React from 'react';
import { ActiveLink } from '@/components/ui/ActiveLink/ActiveLink';
import useScrollBox from '@/components/hooks/useScrollBox';
import { IMenu } from '@/lib/types';
import styles from '../styles.module.scss';

const Menu: React.FunctionComponent<{
  menu: IMenu;
}> = (props) => {
  const { menu } = props;
  const scrollWrapperRef = React.useRef<HTMLDivElement | null>(null);

  useScrollBox(scrollWrapperRef);

  return (
    <div className={styles['header__menu']}>
      <div
        className={styles['header__menu__scroller']}
        ref={scrollWrapperRef}
      >
        <ul className={styles['header__menu__items']}>
          {menu.links.map((item, linkIndex) => (
            <li key={linkIndex}>
              <ActiveLink href={item.url}>{item.label}</ActiveLink>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

Menu.displayName = 'Menu';

export default Menu;
