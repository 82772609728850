'use client';

import * as React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Icon from '@/components/ui/Icon';
import { Overlay as BlueprintOverlay } from './blueprint';
import styles from './styles.module.scss';

const Overlay: React.FunctionComponent<{
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  customHeader?: React.ReactNode;
  zIndex?: number;
  layout: 'default' | 'mobilemenu' | 'mobilesearch';
  className?: string;
  hideClose?: boolean;
  lazy?: boolean;
}> = (props) => {
  let z = 101;
  if (props.zIndex) {
    z = z + props.zIndex;
  }
  const { t } = useTranslation();
  return (
    <BlueprintOverlay
      isOpen={props.isOpen}
      onClose={props.onClose}
      transitionDuration={150}
      canOutsideClickClose={false}
      hasBackdrop={false}
      lazy={typeof props.lazy !== 'undefined' ? props.lazy : true}
      usePortal={true}
      canEscapeKeyClose={false}
      enforceFocus={false}
      className={styles[`overlay-${props.layout}`]}
    >
      <div
        className={classNames(styles[`overlay-${props.layout}__overlayholder`], styles['overlayholder'])}
        style={{ zIndex: z }}
      >
        <div className={classNames(styles[`overlay-${props.layout}`], props.className)}>
          <div className={classNames(styles[`overlay-${props.layout}__holder`], 'overlay__holder')}>
            <div className={classNames(styles[`overlay-${props.layout}__content`], 'overlay__content')}>
              {!props.hideClose && (
                <button
                  className={classNames(styles[`overlay-${props.layout}__close`], 'overlay__close')}
                  onClick={props.onClose}
                  title={t('generic.actions.close')}
                  type="button"
                >
                  <Icon
                    width={100}
                    height={100}
                    kind="close"
                  />
                </button>
              )}
              {props.customHeader}
              <div className={styles[`overlay-${props.layout}__scroller`]}>{props.children}</div>
            </div>
          </div>
        </div>
        <span className={classNames(styles['overlay__backdrop'], 'overlay__backdrop')} />
      </div>
    </BlueprintOverlay>
  );
};

Overlay.displayName = 'Overlay';

export default Overlay;
