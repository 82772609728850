import { DateTime } from 'luxon';

export const getCookie = (name: string) => {
  const regex = new RegExp(`${name}=([^;]+)`);
  const value = regex.exec(document.cookie);
  return value ? decodeURIComponent(value[1]) : null;
};

export const setCookie = (name: string, value: string, validTime: number) => {
  const expiresDate = DateTime.utc().plus({ millisecond: validTime }).toRFC2822();
  document.cookie = `${name}=${value};expires=${expiresDate};path=/;SameSite=Lax; Secure`;
};

export const removeCookie = (name: string) => {
  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; Secure; SameSite=Lax`;
};
