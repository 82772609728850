'use client';

import * as React from 'react';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import Buttons from '@/components/ui/Buttons';
import ButtonIcon from '@/components/ui/ButtonIcon';
import { scrollToTop } from '@/components/helpers/scrollToTop';
import { SiteContext } from '@/components/helpers/siteProvider';
import { IconsAvailable } from '@/components/ui/Icon';
import { getLocalizedPath } from '@/components/helpers/localize';
import { IFooter, IFooterColumn, IFooterContact, IFooterSocial } from '@/lib/types';
import { Locale } from '@/lib/enums/locale.enum';
import Button from '../Button';
import styles from './styles.module.scss';

interface ISocials {
  socials: IFooterSocial[];
}

interface IFooterLogo {
  theme: string;
  name: string;
}

interface IFooterColumns {
  columns: IFooterColumn[];
}

export const FooterContact: React.FunctionComponent<IFooterContact> = (props) => {
  const { address, phone, email } = props;
  return (
    <React.Fragment>
      {address && <p>{address}</p>}
      {phone && <p>{phone}</p>}
      {email && <p>{email}</p>}
    </React.Fragment>
  );
};

function FooterSocials(props: ISocials) {
  const { socials } = props;
  const { t } = useTranslation();
  return (
    <Buttons layout="horizontal">
      {socials.map((social) => {
        let icon: IconsAvailable;
        let label: string;
        switch (social.type) {
          case 'facebook':
            label = 'Facebook';
            icon = 'share-facebook';
            break;
          case 'rss':
            label = 'RSS';
            icon = 'share-rss';
            break;
          case 'x':
            label = 'Twitter';
            icon = 'share-twitter';
            break;
          case 'linkedIn':
            label = 'LinkedIn';
            icon = 'share-linkedin';
            break;
          default:
            icon = 'delete';
            label = 'missing';
        }

        return (
          <Link
            key={social.type}
            href={social.url}
            target="_blank"
            prefetch={false}
          >
            <ButtonIcon
              key={social.type}
              title={t('components.footer.findUs', { label: label })}
              icon={icon}
              theme="white-transparent"
            />
          </Link>
        );
      })}
    </Buttons>
  );
}

function FooterLogo(props: IFooterLogo) {
  const { theme, name } = props;
  return (
    <p className={styles['footer__logo']}>
      <Link href="/">
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          src={`/logos/${theme}.svg`}
          alt={name}
        />
      </Link>
    </p>
  );
}

function FooterColumns(props: IFooterColumns) {
  const { columns } = props;
  return (
    <div className={styles['footer__columns']}>
      {columns.map((column, sectionIndex) => (
        <div
          className={styles['footer__columns__column']}
          key={sectionIndex}
        >
          <ul className={styles['footer__list']}>
            {column.links.map((row, rowIndex) => (
              <li key={rowIndex}>
                <Link
                  href={row.url}
                  prefetch={false}
                >
                  {row.label}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
}

const Footer: React.FunctionComponent<IFooter> = (props) => {
  const { columns, socials, contact } = props;
  const { t, i18n } = useTranslation();
  const { theme, name } = React.useContext(SiteContext);
  return (
    <div className={styles['footer']}>
      <div className={styles['footer__container']}>
        <div className={styles['footer__scroll-to-top']}>
          <ButtonIcon
            title={t('generic.actions.scrollToTop')}
            icon="arrow03-up"
            size="large"
            theme="primary"
            onClick={(e) => {
              e.preventDefault();
              scrollToTop();
            }}
          />
        </div>
        <div className={styles['footer__areas']}>
          <div className={styles['footer__areas__contact']}>
            <FooterLogo
              theme={theme}
              name={name}
            />
            <FooterContact
              email={contact.email}
              phone={contact.phone}
              address={contact.address}
            />
            <p className={styles['footer__logo']}>
              <a
                href="https://www.aripaev.ee"
                target="_blank"
                rel="noopener noreferrer"
              >
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img
                  src={`/logos/aripaev.svg`}
                  alt="Äripäev"
                />
              </a>
            </p>
          </div>
          <div className={styles['footer__areas__links']}>
            <FooterColumns columns={columns} />
          </div>
          <div className={styles['footer__areas__social']}>
            <FooterSocials socials={socials} />
          </div>
          <div className={styles['footer__areas__booking']}>
            <p>{t('components.menu.consultation')}</p>
            <Buttons>
              <Button
                title={t('components.menu.booking')}
                iconRight="calendar-month"
                theme="primary"
                href={getLocalizedPath('contact', 'contact', i18n.language as Locale)}
              />
            </Buttons>
          </div>
        </div>
        <div className={styles['footer__copyright']}>{`© Infopank 2015-${new Date().getFullYear()}`}</div>
      </div>
    </div>
  );
};

Footer.displayName = 'Footer';

export default Footer;
