/* eslint-disable */
'use client';

import { useReportWebVitals } from 'next/web-vitals';
import * as React from 'react';
// https://developers.google.com/speed/docs/insights/v5/about
// https://blog.cloudflare.com/inp-get-ready-for-the-new-core-web-vital
const limits: Record<string, number> = {
  FCP: 1800,
  LCP: 2500,
  CLS: 0.1,
  INP: 200,
  TTFB: 800,
  FID: 100,
};

export const WebVitals: React.FunctionComponent = () => {
  useReportWebVitals((metric) => {
    console.log({
      name: metric.name,
      navType: metric.navigationType,
      rating: metric.rating,
      value: parseFloat(metric.value.toFixed(3)),
      limit: limits[metric.name],
      POL: ((metric.value / limits[metric.name]) * 100).toFixed(2) + '%',
    });
  });
  return null;
};
