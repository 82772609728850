'use client';
import * as React from 'react';
import styles from './styles.module.scss';

export default function Highlighter({ text = '', search = '' }: { text: string; search: string }): React.JSX.Element | string {
  const re = React.useMemo(() => {
    const SPECIAL_CHAR_RE = /([.?*+^$[\]\\(){}|-])/g;
    const escapedSearch = search.replace(SPECIAL_CHAR_RE, '\\$1');
    return new RegExp(`(${escapedSearch})`, 'i');
  }, [search]);

  if (search === '') {
    return text;
  }

  return (
    <React.Fragment>
      {text
        .split(re)
        .filter((part) => part !== '')
        .map((part, i) =>
          re.test(part) ? (
            <span
              className={styles['highlighter']}
              key={`${part}-${i}`}
            >
              {part}
            </span>
          ) : (
            part
          ),
        )}
    </React.Fragment>
  );
}
