import { fetchData } from '@/lib/fetch-data';
import { SearchResponse } from '@/apiTypes/types';
import { SearchParams } from '@/components/search/SearchResults';

type PaginationProps = {
  from: number;
  limit: number;
};

type SearchOptionsProps = {
  onlyRegisteredCompanies: string;
};

export async function loadSearch(
  search: SearchParams,
  pagination: PaginationProps,
  searchOptions?: SearchOptionsProps,
): Promise<SearchResponse> {
  const params: Record<string, string> = {
    query: search?.query || '',
    scope: search?.scope || '',
    from: pagination.from.toString(),
    limit: pagination.limit.toString(),
  };

  if (searchOptions?.onlyRegisteredCompanies) {
    params.onlyRegisteredCompanies = searchOptions.onlyRegisteredCompanies;
  }

  const result = await fetchData<SearchResponse>(`${process.env.NEXT_PUBLIC_SEARCH_API_URL}/v2/search/infopank`, {
    params,
    revalidate: 0,
  });
  if (!result) {
    const fallbackResult = {
      items: [],
      pagination: {
        from: pagination.from,
        limit: pagination.limit,
        total: 0,
        isEnd: true,
      },
    };
    return {
      companies: fallbackResult,
      persons: fallbackResult,
    };
  }
  return result;
}
