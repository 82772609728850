'use client';

import * as React from 'react';
import styles from './styles.module.scss';

export interface IStickyProps {
  children?: React.ReactNode;
}

const Sticky: React.FunctionComponent<IStickyProps> = (props) => {
  return (
    <React.Fragment>
      <div className={styles['sticky']}>{props.children}</div>
      <div className={styles['sticky__mock']} />
    </React.Fragment>
  );
};

Sticky.displayName = 'Sticky';

export default Sticky;
