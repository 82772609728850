'use client';

import * as React from 'react';
import { useOverlay } from '@/components/hooks/useOverlay';
import Overlay from '@/components/ui/Overlay';
import LayoutOverlay from '@/components/ui/LayoutOverlay';
import Block from '@/components/ui/Block';
import { ActiveLink } from '@/components/ui/ActiveLink/ActiveLink';
import LanguageButton from '@/components/ui/Header/LanguageButton';
import { IMenu } from '@/lib/types';
import { Overlays } from '@/components/ui/Overlays';
import { AuthContext } from '@/components/auth/AuthProvider';
import { Locale } from '@/lib/enums/locale.enum';
import NotificationsButton from '@/components/ui/Header/NotificationsButton';
import styles from './styles.module.scss';

const MenuMobile: React.FunctionComponent<{
  menu: IMenu;
  locale: Locale;
}> = (props) => {
  const { isOverlayOpen, zIndexOfOverlay, closeOverlay } = useOverlay('mobilemenu');
  const { overlaysActions } = React.useContext(Overlays);
  const { user } = React.useContext(AuthContext);
  const close = () => {
    overlaysActions({
      action: 'close',
      name: 'mobilemenu',
    });
  };
  return (
    <Overlay
      isOpen={isOverlayOpen}
      onClose={closeOverlay}
      layout="mobilemenu"
      zIndex={zIndexOfOverlay}
      hideClose={true}
      customHeader={
        <ul className={styles['menu-mobile__tools']}>
          <li>
            <LanguageButton locale={props.locale} />
          </li>
          {user && (
            <li>
              <NotificationsButton />
            </li>
          )}
        </ul>
      }
    >
      <LayoutOverlay
        size="mobilemenu"
        className={styles['menu-mobile']}
      >
        <Block theme="lightest">
          <ul className={styles['menu-mobile__links']}>
            {props.menu.links.map((link) => (
              <li key={link.url + link.label}>
                <ActiveLink
                  href={link.url}
                  onClick={close}
                >
                  {link.label}
                </ActiveLink>
                {link.children && link.children.length > 0 && (
                  <ul className={styles['menu-mobile__links__sub']}>
                    {link.children.map((sub) => (
                      <li key={sub.url + sub.label}>
                        <ActiveLink
                          href={sub.url}
                          onClick={close}
                        >
                          {sub.label}
                        </ActiveLink>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </Block>
      </LayoutOverlay>
    </Overlay>
  );
};

export default MenuMobile;
