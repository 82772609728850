'use client';

import * as React from 'react';

export interface PortalContextOptions {
  portalClassName?: string;
  portalContainer?: HTMLElement;
}

export const PortalContext = React.createContext<PortalContextOptions>({});

export const PortalProvider = ({ children, portalClassName, portalContainer }: React.PropsWithChildren<PortalContextOptions>) => {
  const contextOptions = React.useMemo<PortalContextOptions>(
    () => ({
      portalClassName,
      portalContainer,
    }),
    [portalClassName, portalContainer],
  );
  return <PortalContext.Provider value={contextOptions}>{children}</PortalContext.Provider>;
};
