import * as React from 'react';
import { usePathname, useRouter } from 'next/navigation';
import Button from '@/components/ui/Button';
import { useOverlay } from '@/components/hooks/useOverlay';
import { getBasePath, getLocalizedPath } from '@/components/helpers/localize';
import { afterOverlayClose } from '@/lib/helpers/overlays';
import { Locale } from '@/lib/enums/locale.enum';
import styles from '../styles.module.scss';

interface IProps {
  locale: Locale;
}

const LanguageButton: React.FC<IProps> = (props) => {
  const pathname = usePathname();
  const router = useRouter();
  const { closeOverlay } = useOverlay('mobilemenu');
  const [currentLanguage, setCurrentLanguage] = React.useState<Locale>(props.locale);

  const handleLanguageChange = (language: Locale) => {
    const basePath = getBasePath(pathname);
    const localizedPath = getLocalizedPath(basePath, pathname, language);

    closeOverlay();
    afterOverlayClose(() => {
      setCurrentLanguage(language);
      router.push(localizedPath);
      router.refresh();
    });
  };

  return (
    <Button
      title={currentLanguage.toUpperCase()}
      theme="white-transparent"
      menu={[
        {
          label: 'Eesti keeles',
          onClick: () => handleLanguageChange(Locale.et),
        },
        {
          label: 'По-русски',
          onClick: () => handleLanguageChange(Locale.ru),
        },
        {
          label: 'In English',
          onClick: () => handleLanguageChange(Locale.en),
        },
      ]}
      iconRight="arrow01-down"
      className={styles['header__buttons__languages']}
    />
  );
};

LanguageButton.displayName = 'LanguageButton';

export default LanguageButton;
