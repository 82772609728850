import { Duration } from 'luxon';
import { UpgradeFlow, User } from '@/components/auth/AuthProvider';

export const getUserProductCodes = (user: User | null, channelId: string) => user?.locks?.[channelId]?.locked || [];

export const getUserUpgradeDetails = (user: User | null) => user?.upgradeDetails?.[0];

export const getUpgradeFlow = (user: User | null, channelId: string): UpgradeFlow | null => {
  if (!user) {
    return null;
  }
  /** Upgrade details from token. */
  const upgrade = getUserUpgradeDetails(user);
  if (upgrade) {
    return {
      flowType: upgrade.upgradeFlowType,
      productCodes: [upgrade.currentProductCode],
    };
  }
  /** Token might be missing upgrade details, Fallback to email flow if user has a subscription. */
  const productCodes = getUserProductCodes(user, channelId);
  if (productCodes?.length) {
    return {
      flowType: 'EMAIL',
      productCodes,
    };
  }
  return null;
};

export const getUserExp = (user: User) => user.exp || Duration.fromObject({ years: 5 }).toMillis();
