import { scrollTo } from '@/components/helpers/scrollTo';
import { getScrollParent } from '@/components/helpers/getScrollParent';

// Generic scroll to top helper

export function scrollToTop(element?: HTMLElement) {
  const elem = element || document.activeElement;
  if (elem) {
    void scrollTo(getScrollParent(elem), {
      top: 0,
      behavior: 'smooth',
    });
  }
}
