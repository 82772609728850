'use client';

import * as React from 'react';

// Previous

export function usePrevious(value: unknown) {
  const ref = React.useRef<unknown>();
  React.useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}
