import { DEBOUNCE_DELAY, SEARCH_MIN_LENGTH } from '@/lib/constants';
import { loadSearch } from '@/lib/search/loadSearch';
import { SearchScope } from '@/lib/enums/searchScope';
import { debounce } from '@/lib/helpers/debounce';

export const loadAutocompleteSearch = debounce(async (keyword: string, scope: SearchScope) => {
  if (keyword.length >= SEARCH_MIN_LENGTH) {
    return await loadSearch(
      {
        query: keyword,
        scope,
      },
      {
        from: 0,
        limit: 5,
      },
      {
        onlyRegisteredCompanies: '1',
      },
    );
  } else {
    const fallbackResult = {
      items: [],
      pagination: {
        from: 0,
        limit: 5,
        total: 0,
        isEnd: true,
      },
    };

    return {
      companies: fallbackResult,
      persons: fallbackResult,
    };
  }
}, DEBOUNCE_DELAY);
