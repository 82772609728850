import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/navigation';
import ButtonIcon from '@/components/ui/ButtonIcon';
import { useOverlay } from '@/components/hooks/useOverlay';
import { getLocalizedPath } from '@/components/helpers/localize';
import { Locale } from '@/lib/enums/locale.enum';
import { afterOverlayClose } from '@/lib/helpers/overlays';
import styles from '../styles.module.scss';

const NotificationsButton: React.FC = () => {
  const router = useRouter();
  const { closeOverlay } = useOverlay('mobilemenu');
  const { i18n } = useTranslation();
  return (
    <ButtonIcon
      title={''}
      icon="notifications"
      theme="white-transparent"
      notifications={''}
      onClick={() => {
        closeOverlay();
        afterOverlayClose(() => router.push(`${getLocalizedPath('my-account', 'my-account', i18n.language as Locale)}?tab=3`));
      }}
      className={styles['header__buttons__notifications']}
    />
  );
};

NotificationsButton.displayName = 'NotificationsButton';

export default NotificationsButton;
