import { Locale } from '@/lib/enums/locale.enum';

export type AuthChannel = {
  name: string;
};

export interface Config {
  onLogin?: (res: { jwt: string; returnUrl: string }) => void;
  authChannels: AuthChannel[];
  lang: string;
  challengeSiteKey?: string;
}

export type SsoPlugin = {
  init: (el: HTMLElement, config: Config) => void;
  open: (view: string, callbacks: { onLogin?: (jwt: string) => void }) => void;
};

const getLang = (locale: Locale) => {
  switch (locale) {
    case Locale.en:
      return 'en-US'; // todo :: sso add en
    case Locale.ru:
      return 'ru-RU';
    default:
      return 'et-EE';
  }
};

export const initPlugin = (containerId: string, locale: Locale, challengeSiteKey: string | undefined) => {
  const el = document.getElementById(containerId);
  if (el) {
    window.ssoPlugin?.init(el, {
      authChannels: [{ name: 'emailAndPassword' }, { name: 'facebook' }, { name: 'google' }],
      lang: getLang(locale),
      onLogin: ({ jwt }) => {
        window.location.href = `${window.location.pathname}?jwt=${jwt}`;
      },
      challengeSiteKey,
    });
  }
};

export const initLogin = () => window.ssoPlugin?.open('login', {});

export const initIpLogin = () => {
  const url = new URL(`${process.env.NEXT_PUBLIC_SSO_API_URL}/auth/login/ip/infopank`);
  url.searchParams.set('returnUrl', window.location.href);
  window.open(url, '_self');
};
