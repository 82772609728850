'use client';

import * as React from 'react';

// Keypress hook

export function useKeypress(key: string, action: (e: KeyboardEvent) => void, deps: React.DependencyList) {
  React.useEffect(() => {
    function onKeyup(e: KeyboardEvent) {
      if (e.key === key) {
        action(e);
      }
    }

    window.addEventListener('keyup', onKeyup);
    return () => window.removeEventListener('keyup', onKeyup);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
