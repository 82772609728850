'use client';

import * as React from 'react';
import Script from 'next/script';
import { useContext } from 'react';
import { initPlugin } from '@/lib/sso';
import { SiteContext } from '@/components/helpers/siteProvider';
import useTurnstileSiteKey from '@/lib/hooks/useTurnstileSiteKey';

const loginContainerId = 'login-container';

const SsoPlugin: React.FC = () => {
  const { locale } = useContext(SiteContext);
  const siteKey = useTurnstileSiteKey('sso');
  return (
    <React.Fragment>
      <div id={loginContainerId}></div>
      <Script
        src={process.env.NEXT_PUBLIC_SSO_PLUGIN_URL}
        type="module"
        strategy="afterInteractive"
        onLoad={() => initPlugin(loginContainerId, locale, siteKey)}
      />
    </React.Fragment>
  );
};

export default SsoPlugin;
