'use client';

import * as React from 'react';

function useScrollBox(scrollRef: React.RefObject<HTMLDivElement>) {
  const [clickStart, setClickStart] = React.useState<[number, number]>();
  const [scrollStart, setScrollStart] = React.useState<[number, number]>();
  const [isDragging, setIsDragging] = React.useState(false);

  const scrollWrapperCurrent = scrollRef.current;

  React.useEffect(() => {
    const c = scrollRef.current;
    if (c) {
      const handleDragStart = (e: MouseEvent) => {
        setClickStart([e.screenX, e.screenY]);
        setScrollStart([c.scrollLeft, c.scrollTop]);
      };

      const handleDragMove = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (clickStart !== undefined && scrollStart !== undefined) {
          const touchDeltaX = (clickStart[0] || 0) - e.screenX;
          const touchDeltaY = (clickStart[1] || 0) - e.screenY;
          c.scrollLeft = (scrollStart[0] || 0) + touchDeltaX;
          c.scrollTop = (scrollStart[1] || 0) + touchDeltaY;

          if (Math.abs(touchDeltaX) > 1 || Math.abs(touchDeltaY) > 1) {
            setIsDragging(true);
          }
        }
      };

      const handleDragEnd = () => {
        setClickStart(undefined);
        setScrollStart(undefined);
        setIsDragging(false);
      };

      if (scrollRef.current.ontouchstart === undefined) {
        scrollRef.current.onmousedown = handleDragStart;
        scrollRef.current.onmousemove = handleDragMove;
        scrollRef.current.onmouseup = handleDragEnd;
        scrollRef.current.onmouseleave = handleDragEnd;
      }
    }
  }, [scrollWrapperCurrent, clickStart, isDragging, scrollStart, scrollRef]);

  return {
    clickStart,
    scrollStart,
    isDragging,
  };
}

export default useScrollBox;
