'use client';

import * as React from 'react';
import { useOverlay } from '@/components/hooks/useOverlay';
import Overlay from '@/components/ui/Overlay';
import SearchField from '@/components/ui/SearchField';

const SearchMobile: React.FunctionComponent = () => {
  const { isOverlayOpen, zIndexOfOverlay, closeOverlay } = useOverlay('mobilesearch');
  return (
    <Overlay
      isOpen={isOverlayOpen}
      onClose={closeOverlay}
      layout="mobilesearch"
      zIndex={zIndexOfOverlay}
      hideClose={true}
      lazy={false}
    >
      <SearchField isOverlay={true} />
    </Overlay>
  );
};

export default SearchMobile;
