'use client';

import * as React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useToaster } from '@/components/hooks/useToaster';
import { useTimeout } from '@/components/hooks/useTimeout';
import ButtonIcon from '@/components/ui/ButtonIcon';
import Button, { IButtonProps } from '@/components/ui/Button';
import Icon from '@/components/ui/Icon';
import Buttons from '@/components/ui/Buttons';
import styles from './styles.module.scss';

export interface IToastProps {
  id?: string;
  text?: string;
  description?: string;
  intent?: 'default' | 'warning' | 'danger' | 'success';
  buttons?: IButtonProps[];
}

const Toast: React.FunctionComponent<IToastProps> = (props) => {
  const { t } = useTranslation();
  const { closeToast } = useToaster();
  const [isOpen, setIsOpen] = React.useState(false);
  const [timerHiding, setTimerHiding] = React.useState<number | null>(null);
  useTimeout(() => {
    setTimerHiding(null);
    closeToast({ id: props.id });
  }, timerHiding);

  React.useEffect(() => {
    setIsOpen(true);
  }, []);

  return (
    <div
      className={classNames(styles['toast'], {
        [styles['is-open']]: isOpen,
        [styles['is-closing']]: !!timerHiding,

        [styles['intent-default']]: props.intent === 'default' || !props.intent,
        [styles['intent-danger']]: props.intent === 'danger',
        [styles['intent-warning']]: props.intent === 'warning',
        [styles['intent-success']]: props.intent === 'success',
      })}
    >
      <div className={styles['toast__wrapper']}>
        <div className={styles['toast__inner']}>
          <Icon
            width={24}
            height={24}
            kind="info"
            className={styles['toast__icon']}
          />
          <div className={styles['toast__text']}>
            <div className={styles['toast__text__title']}>{props.text}</div>
            {props.description && <div className={styles['toast__text__description']}>{props.description}</div>}
            {props.buttons && props.buttons.length > 0 && (
              <Buttons size="small">
                {props.buttons.map((b, bi) => (
                  <Button
                    key={bi}
                    size="small"
                    {...b}
                  />
                ))}
              </Buttons>
            )}
          </div>
          <div className={styles['toast__close']}>
            <ButtonIcon
              title={t('generic.actions.close')}
              icon="close"
              size="small"
              theme="transparent"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                setTimerHiding(250);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

Toast.displayName = 'Toast';

export default Toast;
