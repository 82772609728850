import { decode } from 'jsonwebtoken';

export const decodeJwt = <T>(jwt: string | null): T | null => {
  if (jwt) {
    try {
      return decode(jwt) as T;
    } catch {
      //
    }
  }
  return null;
};
