'use client';

import * as React from 'react';
import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import SsoPlugin from '@/components/auth/SsoPlugin';
import { getCookie, removeCookie, setCookie } from '@/lib/helpers/cookie';
import { decodeJwt } from '@/lib/helpers/decodeJwt';
import { fetchData } from '@/lib/fetch-data';
import { getUpgradeFlow, getUserProductCodes } from '@/lib/helpers/user';
import { CookieKey } from '@/lib/enums/cookieKey.enum';
import { SiteContext } from '@/components/helpers/siteProvider';
import IpAccessDetector from '@/components/auth/IpAccessDetector';

type UpgradeFlowType = 'PAYMENTMODULE' | 'EMAIL';

/** PRO upgrade */
export type UpgradeDetails = {
  upgradeFlowType: 'PAYMENTMODULE' | 'EMAIL';
  currentProductCode: string;
  currentTelmosCode: string;
  targetProductCode: string;
};

export type User = {
  jti: string;
  iat: number;
  exp: number;
  iss: string;
  profileId: string;
  doctorId: string | null;
  imp: number;
  type: 'login' | 'ip';
  ip?: string;
  email: string;
  firstName: string;
  lastName: string;
  name: string;
  permissions_expire_at: number;
  isLocked: boolean;
  productCodes: string[];
  locks: {
    [channelId: string]: { locked: string[] };
  };
  upgradeDetails: UpgradeDetails[];
  multi_login_warning: boolean;
};

export type UpgradeFlow = {
  flowType: UpgradeFlowType;
  productCodes: string[];
};

interface IAuthContext {
  user: User | null;
  jwt: string | null;
  upgradeFlow: UpgradeFlow | null;
  productCodes: string[];
  logout: () => void;
  initialized: boolean;
}

export const AuthContext = createContext<IAuthContext>({
  user: null,
  jwt: null,
  upgradeFlow: null,
  productCodes: [],
  logout: () => void 0,
  initialized: false,
});

const logoutSso = async (jwt: string) =>
  fetchData(`${process.env.NEXT_PUBLIC_SSO_API_URL}/auth/logout`, {
    method: 'DELETE',
    bearer: jwt,
    credentials: 'include',
  });

export const getUserOrder = async (jwt: string): Promise<{ orderCode: string; exp: Date } | null> =>
  fetchData(`${process.env.NEXT_PUBLIC_SSO_API_URL}/order/infopank`, {
    bearer: jwt,
    credentials: 'include',
  });

const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { channelId } = useContext(SiteContext);
  const [auth, setAuth] = useState<Omit<IAuthContext, 'logout'>>({
    user: null,
    jwt: null,
    upgradeFlow: null,
    productCodes: [],
    initialized: false,
  });

  const initialize = async (jwt: string | null) => {
    const user = decodeJwt<User>(jwt);
    setAuth({
      user,
      jwt,
      upgradeFlow: getUpgradeFlow(user, channelId),
      productCodes: getUserProductCodes(user, channelId),
      initialized: true,
    });

    if (user) {
      const order = await getUserOrder(jwt!);
      if (order?.orderCode) {
        setCookie(CookieKey.orderCode, order.orderCode, new Date(order.exp).getTime());
      }
    }

    return user;
  };

  useEffect(() => {
    void initialize(getCookie(CookieKey.jwt));
  }, []);

  const logout = async () => {
    if (!auth.jwt) {
      return;
    }
    try {
      await logoutSso(auth.jwt);
    } finally {
      removeCookie(CookieKey.jwt);
      removeCookie(CookieKey.orderCode);
      window.location.reload();
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...auth,
        logout: () => void logout(),
      }}
    >
      <IpAccessDetector />
      <SsoPlugin />
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
