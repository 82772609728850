import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/node/components/auth/AuthProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/node/components/content/Layout/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/node/components/dictionary/DictionaryProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/node/components/helpers/siteProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WebVitals"] */ "/home/node/components/helpers/webVitals.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/node/components/subscriptions/SubscriptionsProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["OverlaysProvider"] */ "/home/node/components/ui/Overlays/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ToasterProvider"] */ "/home/node/components/ui/Toaster/index.tsx");
;
import(/* webpackMode: "eager" */ "/home/node/styles/index.scss");
;
import(/* webpackMode: "eager" */ "/home/node/node_modules/next/font/google/target.css?{\"path\":\"app/fonts.ts\",\"import\":\"IBM_Plex_Sans\",\"arguments\":[{\"weight\":[\"400\",\"500\"],\"subsets\":[\"latin\",\"cyrillic\"],\"variable\":\"--font-base\"}],\"variableName\":\"plex\"}");
