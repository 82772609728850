import { DateTime, Duration } from 'luxon';
import slugify from 'slugify';
import { siteChannelUrls } from '@/lib/helpers/environments';

export const formatDuration = (seconds: number) => Duration.fromObject({ seconds }).toFormat('mm:ss');
export const aripaev = 'aripaev';

export const slugifyString = (val: string): string =>
  slugify(val, {
    replacement: '-',
    lower: true,
    remove: /[?!.()*@:;"'+]/g,
  });

export const defaultLocale = () => process.env.NEXT_PUBLIC_DEFAULT_LOCALE;

type DateFormat = 'dd.LL.yyyy' | 'yyyy-LL-dd';
export const formatDate = (dateStr: string | undefined | null, format?: DateFormat): string | null => {
  if (!dateStr) {
    return null;
  }
  if (format) {
    const datetime = DateTime.fromISO(dateStr);
    return datetime.isValid ? datetime.toFormat(format) : null;
  }
  return new Intl.DateTimeFormat(defaultLocale(), {
    dateStyle: 'short',
  }).format(new Date(dateStr));
};

export const formatUrl = (permalink: string, channels: string[]): string => {
  if (channels.length === 0) {
    return `${permalink}`;
  }
  const primaryChannel = channels.length > 1 && channels.includes(aripaev) ? aripaev : channels[0];
  const baseUrl = siteChannelUrls[primaryChannel];
  return `${baseUrl}${permalink}`;
};
