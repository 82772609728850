import { useTranslation } from 'react-i18next';
import { Furniture } from '@/lib/types';

export const useFurniture = (): Furniture => {
  const { t } = useTranslation();

  return {
    channelId: 'infopank',
    menu: {
      links: [
        {
          children: [
            {
              label: t('components.menu.backgroundInformation'),
              url: '/teenused-ja-tooted/taustainfo',
            },
            {
              label: t('components.menu.debtRegister'),
              url: '/teenused-ja-tooted/volaregister',
            },
            {
              label: t('components.menu.marketReview'),
              url: '/teenused-ja-tooted/konkurentsiraportid',
            },
            {
              label: t('components.menu.marketingLists'),
              url: '/teenused-ja-tooted/turundusnimekirjad',
            },
            {
              label: t('components.menu.salesAgent'),
              url: '/teenused-ja-tooted/muugiagent',
            },
            {
              label: t('components.menu.certificates'),
              url: '/teenused-ja-tooted/sertifikaadid',
            },
            {
              label: t('components.menu.api'),
              url: '/teenused-ja-tooted/andmeliidesed',
            },
          ],
          label: t('components.menu.servicesAndProducts'),
          url: '/teenused-ja-tooted',
        },
        {
          children: [],
          label: t('components.menu.pricingPackages'),
          url: '/hinnapaketid',
        },
        {
          children: [],
          label: t('components.menu.blog'),
          url: '/blogi',
        },
        {
          children: [],
          label: t('components.menu.aboutUs'),
          url: '/meist',
        },
        {
          children: [],
          label: t('components.menu.contact'),
          url: '/kontakt',
        },
      ],
    },
    footer: {
      contact: {
        address: 'Vana-Lõuna 39/1, 19094 Tallinn',
        phone: '(+372) 667 0008',
        email: 'info@infopank.ee',
      },
      columns: [
        {
          label: '#1',
          links: [
            {
              label: t('components.menu.servicesAndProducts'),
              url: '/teenused-ja-tooted',
            },
            {
              children: [],
              label: t('components.menu.pricingPackages'),
              url: '/hinnapaketid',
            },
            {
              children: [],
              label: t('components.menu.blog'),
              url: '/blogi',
            },
            {
              children: [],
              label: t('components.menu.aboutUs'),
              url: '/meist',
            },
            {
              children: [],
              label: t('components.menu.contact'),
              url: '/kontakt',
            },
            {
              children: [],
              label: t('components.menu.termsOfService'),
              url: '/kasutustingimused',
            },
          ],
        },
      ],
      socials: [
        {
          type: 'facebook',
          url: 'https://www.facebook.com/infopank/',
        },
        {
          type: 'linkedIn',
          url: 'https://ee.linkedin.com/company/infopank',
        },
      ],
    },
  };
};
