'use client';

import * as React from 'react';
import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { AuthContext, User } from '@/components/auth/AuthProvider';
import { addSubscriptionCompany } from '@/lib/monitoring/addSubscriptionCompany';
import { removeSubscriptionCompany } from '@/lib/monitoring/removeSubscriptionCompany';
import { InfopankMonitoredCompanies } from '@/apiTypes/types';
import { loadMonitoredCompanies } from '@/lib/account/loadMonitoredCompanies';
import { getCookie } from '@/lib/helpers/cookie';
import { CookieKey } from '@/lib/enums/cookieKey.enum';
import { IInfopankApiReqProps } from '@/lib/interfaces/infopankApiReq.interface';
import { getSubscriptionStatus } from '@/lib/monitoring/getSubscriptionStatus';

interface ISubscriptionsContext {
  subscriptions: InfopankMonitoredCompanies[];
  add: (id: string, name?: string) => void;
  remove: (id: string) => void;
  subscriptionStatus: (id: string) => Promise<{ subscribed: boolean; couldSubscribe: boolean }>;
  refreshSubscriptions: () => void;
}

export const SubscriptionsContext = createContext<ISubscriptionsContext>({
  subscriptions: [],
  add: () => void 0,
  remove: () => void 0,
  subscriptionStatus: () =>
    Promise.resolve({
      subscribed: false,
      couldSubscribe: false,
    }),
  refreshSubscriptions: () => void 0,
});

const SubscriptionsProvider: React.FC<PropsWithChildren & IInfopankApiReqProps> = ({ children, params }) => {
  const { user } = useContext(AuthContext);
  const [subscriptions, setSubscriptions] = useState<InfopankMonitoredCompanies[]>([]);

  const getSubscriptions = async (user: User, orderCode: string | null) => {
    const subscriptions = await loadMonitoredCompanies(user, orderCode, { params });
    setSubscriptions(subscriptions || []);
  };

  const refreshSubscriptions = () => {
    if (user) {
      const orderCode = getCookie(CookieKey.orderCode);
      void getSubscriptions(user, orderCode);
    }
  };

  useEffect(() => {
    refreshSubscriptions();
  }, [user]);

  const add = async (id: string, name?: string) => {
    if (!user) {
      return;
    }
    const orderCode = getCookie(CookieKey.orderCode);
    await addSubscriptionCompany({
      externalId: id,
      params: { params },
      user,
      orderCode,
    });
    setSubscriptions((prev) => [
      ...prev,
      {
        companyId: Number(id),
        name: name || '',
      },
    ]);
  };

  const getCompanySubscriptionStatus = async (id: string) => {
    const initialSubscriptionStatus = {
      subscribed: false,
      couldSubscribe: false,
    };
    if (!user) {
      return initialSubscriptionStatus;
    }

    const data = await getSubscriptionStatus({
      externalId: id,
      user,
      orderCode: getCookie(CookieKey.orderCode),
    });

    if (!data) {
      return initialSubscriptionStatus;
    }

    return {
      subscribed: data.subscribed,
      couldSubscribe: data.couldSubscribe,
    };
  };

  const remove = async (id: string) => {
    if (!user) {
      return;
    }
    const orderCode = getCookie(CookieKey.orderCode);
    await removeSubscriptionCompany({
      externalId: id,
      params: { params },
      user,
      orderCode,
    });
    setSubscriptions((prev) => prev.filter((sub) => sub.companyId !== Number(id)));
  };

  return (
    <SubscriptionsContext.Provider
      value={{
        add: (id, name) => void add(id, name),
        remove: (id) => void remove(id),
        subscriptionStatus: async (id) => await getCompanySubscriptionStatus(id),
        subscriptions: subscriptions,
        refreshSubscriptions,
      }}
    >
      {children}
    </SubscriptionsContext.Provider>
  );
};

export default SubscriptionsProvider;
