import { InfopankInternalHrefType } from '@/apiTypes/types';
import { slugifyString } from '@/lib/helpers/format';
import { getLocalizedPath } from '@/components/helpers/localize';
import { Locale } from '@/lib/enums/locale.enum';

export const getInternalLink = (type: InfopankInternalHrefType, id: number, language: Locale, name?: string) => {
  const slug = name ? `/${slugifyString(name)}` : '';
  const path = type.toLowerCase();
  return `${getLocalizedPath(path, path, language)}/${id}${slug}`;
};
