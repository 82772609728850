'use client';

import * as React from 'react';
import { useOverlayClosing } from '@/components/hooks/useOverlay';
import { ToasterOutlet } from '@/components/ui/Toaster';
import Sticky from '@/components/ui/Sticky';

export interface IHeaderMobileProps {}

const LayoutInteractive: React.FunctionComponent<IHeaderMobileProps> = () => {
  React.useLayoutEffect(() => {
    document.documentElement.style.setProperty('--scrollbar-width', `${window.innerWidth - document.documentElement.clientWidth}px`);
  }, []);
  useOverlayClosing();

  return (
    <Sticky>
      <ToasterOutlet />
    </Sticky>
  );
};

LayoutInteractive.displayName = 'LayoutInteractive';

export default LayoutInteractive;
