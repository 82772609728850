import { useContext } from 'react';
import { SiteContext } from '@/components/helpers/siteProvider';
import { isDevEnv, isLocalEnv } from '@/lib/helpers/environment';

const useTurnstileSiteKey = (type: 'sso') => {
  const { turnstileSiteKey } = useContext(SiteContext);
  return isDevEnv() || isLocalEnv() ? turnstileSiteKey?.[type]?.dev : turnstileSiteKey?.[type]?.live;
};

export default useTurnstileSiteKey;
