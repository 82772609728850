'use client';

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import ButtonIcon from '@/components/ui/ButtonIcon';
import styles from './styles.module.scss';

export interface IOverlayTitleProps {
  title?: React.ReactNode;
  onClose?: () => void;
  goBack?: () => void;
}

const OverlayTitle: React.FunctionComponent<IOverlayTitleProps> = (props) => {
  const { t } = useTranslation();

  if (!props.title && !props.onClose) {
    return null;
  }

  return (
    <div className={styles['overlay-title']}>
      {props.goBack && (
        <div className={styles['overlay-title__button']}>
          <ButtonIcon
            title={t('generic.actions.goBack')}
            icon="arrow03-left"
            size="small"
            theme="transparent"
            onClick={props.onClose}
          />
        </div>
      )}
      {props.title && <div className={styles['overlay-title__title']}>{props.title}</div>}
      {props.onClose && (
        <div className={styles['overlay-title__button']}>
          <ButtonIcon
            title={t('generic.actions.close')}
            icon="close"
            size="small"
            theme="transparent"
            onClick={props.onClose}
          />
        </div>
      )}
    </div>
  );
};

OverlayTitle.displayName = 'OverlayTitle';

export default OverlayTitle;
