type SiteChannels = {
  [key: string]: string;
};

//@todo need one place to store channel data
export const siteChannelUrls: SiteChannels = {
  aripaev: 'https://www.aripaev.ee',
  dv: 'https://www.dv.ee',
  bestmarketing: 'https://www.bestmarketing.ee',
  ehitus: 'https://www.ehitusuudised.ee',
  finants: 'https://www.finantsuudised.ee',
  foundme: 'https://www.foundme.io',
  it: 'https://www.ituudised.ee',
  kaubandus: 'https://www.kaubandus.ee',
  kestlikkus: 'https://www.kestlikkusuudised.ee',
  kinnisvara: 'https://www.kinnisvarauudised.ee',
  logistika: 'https://www.logistikauudised.ee',
  meditsiin: 'https://www.mu.ee',
  metsamajandus: 'https://www.metsamajandusuudised.ee',
  palgauudised: 'https://www.palgauudised.ee',
  personal: 'https://www.personaliuudised.ee',
  pollumajandus: 'https://www.pollumajandus.ee',
  raamatupidaja: 'https://www.raamatupidaja.ee',
  sekretar: 'https://www.sekretar.ee',
  toostus: 'https://www.toostusuudised.ee',
  laanevirumaa: 'https://www.laanevirumaauudised.ee',
  tervis: 'https://www.virtuaalkliinik.ee',
  infopank: process.env.NEXT_PUBLIC_INFOPANK_BASE_URL!,
};
