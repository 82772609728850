'use client';

import * as React from 'react';
import { PropsWithChildren } from 'react';
import Header from '@/components/ui/Header';
import Footer from '@/components/ui/Footer';
import Body from '@/components/ui/Body';
import { localizeFurniture } from '@/lib/helpers/navigation';
import LayoutInteractive from '@/components/content/Layout/interactive';
import { Locale } from '@/lib/enums/locale.enum';
import { useFurniture } from '@/lib/hooks/useFurniture';

interface IProps {
  locale: Locale;
}

const Layout: React.FC<PropsWithChildren<IProps>> = ({ children, locale }) => {
  const furniture = useFurniture();
  const localizedFurniture = localizeFurniture(furniture, locale);

  return (
    <React.Fragment>
      <Header
        menu={localizedFurniture.menu}
        locale={locale}
      />
      <Body>{children}</Body>
      <Footer
        contact={furniture.footer.contact}
        columns={localizedFurniture.footer.columns}
        socials={furniture.footer.socials}
      />
      <LayoutInteractive />
    </React.Fragment>
  );
};

Layout.displayName = 'Layout';

export default Layout;
