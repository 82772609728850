'use client';

import * as React from 'react';
import { PropsWithChildren } from 'react';
import { createInstance } from 'i18next';
import { I18nextProvider } from 'react-i18next';
import { getDictionary } from '@/lib/dictionary';
import { initDictionary } from '@/lib/dictionary/initialize';
import { Locale } from '@/lib/enums/locale.enum';

interface IProps {
  locale: Locale;
}

const DictionaryProvider: React.FC<PropsWithChildren<IProps>> = ({ children, locale }) => {
  const instance = createInstance();
  const dictionary = getDictionary(locale);

  void initDictionary({
    dictionary,
    locale,
    instance,
  });
  return <I18nextProvider i18n={instance}>{children}</I18nextProvider>;
};

export default DictionaryProvider;
