'use client';

import * as React from 'react';
import classNames from 'classnames';
import LoadingContent from '@/components/ui/LoadingContent';
import Icon, { IconsAvailable } from '../Icon';
import styles from './styles.module.scss';

export interface IButtonIconProps {
  /** Human-readable description for SEO & Accessibility purposes */
  title: string;
  /** Icon to display */
  icon: IconsAvailable;
  /** size to use */
  size?: 'default' | 'tiny' | 'small' | 'medium' | 'large';
  /** click event, should not be used with type 'anchor' */
  onClick?: React.DOMAttributes<HTMLButtonElement>['onClick'];
  /** intent variation */
  theme?: 'default' | 'primary' | 'secondary' | 'transparent' | 'white-default' | 'white-primary' | 'white-secondary' | 'white-transparent';
  /** disable clicking */
  disabled?: boolean;
  /** disable clicking and display loader */
  loading?: boolean;
  /** DOM target element name attribute */
  name?: string;
  /** DOM target element id attribute */
  id?: string;
  /** className to append */
  className?: string;
  /** Type param to pass */
  type?: 'button' | 'submit' | 'reset';
  /** Notifications label */
  notifications?: React.ReactNode;
  /** Menu */
  menu?: {
    label: string;
    icon?: IconsAvailable;
    onClick: (e: React.SyntheticEvent) => void;
  }[];
}

/**
 * Displays button
 */
const ButtonIcon: React.FunctionComponent<IButtonIconProps> = (props) => {
  const [dropOpen, setDropOpen] = React.useState(false);
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const dropRef = React.useRef<HTMLUListElement>(null);
  const buttonClass = classNames(
    styles['button-icon'],
    {
      [styles['disabled']]: props.disabled,
      [styles['loading']]: props.loading,

      [styles['size-default']]: !props.size || props.size === 'default',
      [styles['size-tiny']]: props.size === 'tiny',
      [styles['size-small']]: props.size === 'small',
      [styles['size-medium']]: props.size === 'medium',
      [styles['size-large']]: props.size === 'large',

      [styles['theme-default']]: props.theme === 'default' || !props.theme,
      [styles['theme-primary']]: props.theme === 'primary',
      [styles['theme-secondary']]: props.theme === 'secondary',
      [styles['theme-transparent']]: props.theme === 'transparent',
      [styles['theme-white']]: props.theme === 'white-default',
      [styles['theme-white-primary']]: props.theme === 'white-primary',
      [styles['theme-white-secondary']]: props.theme === 'white-secondary',
      [styles['theme-white-transparent']]: props.theme === 'white-transparent',
    },
    props.className,
  );

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target;
      if (dropOpen) {
        if (target && dropRef.current && dropRef.current.contains(target as Node)) {
          setDropOpen(false);
          return;
        }
        if (target && buttonRef.current && !buttonRef.current.contains(target as Node)) {
          setDropOpen(false);
        }
      }
    };
    globalThis.addEventListener('click', handleClickOutside);
    return () => {
      globalThis.removeEventListener('click', handleClickOutside);
    };
  }, [dropOpen, buttonRef, dropRef]);

  const renderContents = (
    <span className={styles['button-icon__inner']}>
      <Icon
        className={styles['button-icon__icon']}
        width={20}
        height={20}
        kind={props.icon}
      />
      {typeof props.notifications !== 'undefined' && <span className={styles['button-icon__notifications']}>{props.notifications}</span>}
      {props.loading && (
        <LoadingContent
          size="tiny"
          layout="absolute"
        />
      )}
    </span>
  );

  return (
    <span
      className={buttonClass}
      draggable={false}
    >
      <button
        className={classNames(styles['button-icon__element'])}
        type={props.type || 'button'}
        title={props.title}
        onClick={(e) => {
          if (typeof props.onClick !== 'undefined') {
            props.onClick(e);
          }
          if (typeof props.menu !== 'undefined' && props.menu.length > 0) {
            setDropOpen(!dropOpen);
          }
        }}
        name={props.name}
        id={props.id}
        aria-busy={props.loading}
        ref={buttonRef}
      >
        {renderContents}
      </button>
      {typeof props.menu !== 'undefined' && props.menu.length > 0 && (
        <ul
          ref={dropRef}
          {...(dropOpen ? {} : { inert: '' })} // https://github.com/facebook/react/issues/17157
          className={classNames(styles['button-icon__drop'], { [styles['open']]: dropOpen })}
        >
          {props.menu.map((item) => (
            <li key={item.label}>
              <button
                role="button"
                onClick={item.onClick}
              >
                <div className={styles['button-icon__drop__inner']}>
                  {item.icon && (
                    <Icon
                      width={20}
                      height={20}
                      kind={item.icon}
                    />
                  )}
                  <div className={styles['button-icon__drop__label']}>{item.label}</div>
                </div>
              </button>
            </li>
          ))}
        </ul>
      )}
    </span>
  );
};

ButtonIcon.displayName = 'ButtonIcon';

export default ButtonIcon;
