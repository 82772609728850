import { pathMap } from '@/lib/dataProviders/localizedPaths';
import { Furniture, IFooterColumn, Link } from '@/lib/types';
import { Locale } from '@/lib/enums/locale.enum';

const getLocalizedPath = (lang: string, key: string): string => {
  const path = pathMap[key];
  if (path && path[lang]) {
    return path[lang];
  }
  return path[Locale.et];
};

export const localizeFurniture = (furniture: Furniture, lang: string): Furniture => {
  const localizeLinks = (links: Link[]) => {
    links.forEach((link) => {
      for (const key in pathMap) {
        if (link.url === pathMap[key][Locale.et]) {
          link.url = getLocalizedPath(lang, key);
          break;
        }
      }
      if (link.children && link.children.length > 0) {
        localizeLinks(link.children);
      }
    });
  };

  localizeLinks(furniture.menu.links);
  furniture.footer.columns.forEach((column: IFooterColumn) => {
    localizeLinks(column.links);
  });

  return furniture;
};
