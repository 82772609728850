'use client';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { PortalContext } from '@/components/ui/PortalProvider';
import styles from './styles.module.scss';

export interface PortalProps {
  className?: string;
  children: React.ReactNode;
  onChildrenMount?: () => void;
  container?: HTMLElement;
  stopPropagationEvents?: Array<keyof HTMLElementEventMap>;
}

export function Portal({ className, stopPropagationEvents, container, onChildrenMount, children }: PortalProps) {
  const context = React.useContext(PortalContext);

  const portalContainer = container ?? context.portalContainer ?? (typeof document !== 'undefined' ? document.body : undefined);

  const [portalElement, setPortalElement] = React.useState<HTMLElement>();

  const createPortalElement = React.useCallback(() => {
    const newPortalElement = document.createElement('div');
    newPortalElement.classList.add(styles['portal']);
    maybeAddClass(newPortalElement.classList, className); // directly added to this portal element
    maybeAddClass(newPortalElement.classList, context.portalClassName); // added via PortalProvider context
    addStopPropagationListeners(newPortalElement, stopPropagationEvents);

    return newPortalElement;
  }, [className, context.portalClassName, stopPropagationEvents]);

  React.useEffect(() => {
    if (portalContainer == null) {
      return;
    }
    const newPortalElement = createPortalElement();
    portalContainer.appendChild(newPortalElement);
    setPortalElement(newPortalElement);

    return () => {
      removeStopPropagationListeners(newPortalElement, stopPropagationEvents);
      newPortalElement.remove();
      setPortalElement(undefined);
    };
  }, [portalContainer, createPortalElement, stopPropagationEvents]);

  React.useEffect(() => {
    if (portalElement != null) {
      onChildrenMount?.();
    }
  }, [portalElement, onChildrenMount]);

  React.useEffect(() => {
    if (portalElement != null) {
      maybeAddClass(portalElement.classList, className);
      return () => maybeRemoveClass(portalElement.classList, className);
    }
    return undefined;
  }, [className, portalElement]);

  React.useEffect(() => {
    if (portalElement != null) {
      addStopPropagationListeners(portalElement, stopPropagationEvents);
      return () => removeStopPropagationListeners(portalElement, stopPropagationEvents);
    }
    return undefined;
  }, [portalElement, stopPropagationEvents]);

  if (typeof document === 'undefined' || portalElement == null) {
    return null;
  } else {
    return ReactDOM.createPortal(children, portalElement);
  }
}

Portal.displayName = 'Portal';

function maybeRemoveClass(classList: DOMTokenList, className?: string) {
  if (className != null && className !== '') {
    classList.remove(...className.split(' '));
  }
}

function maybeAddClass(classList: DOMTokenList, className?: string) {
  if (className != null && className !== '') {
    classList.add(...className.split(' '));
  }
}

function addStopPropagationListeners(portalElement: HTMLElement, eventNames?: Array<keyof HTMLElementEventMap>) {
  eventNames?.forEach((event) => portalElement.addEventListener(event, handleStopProgation));
}

function removeStopPropagationListeners(portalElement: HTMLElement, events?: Array<keyof HTMLElementEventMap>) {
  events?.forEach((event) => portalElement.removeEventListener(event, handleStopProgation));
}

function handleStopProgation(e: Event) {
  e.stopPropagation();
}
