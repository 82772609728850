// Gets DOM node's closest scrolling node

export const getScrollParent = (node: HTMLElement | Element) => {
  let offsetParent: HTMLElement | Element | null = node;
  // eslint-disable-next-line
  while ((offsetParent = offsetParent.parentNode as HTMLElement)) {
    if (offsetParent === document.documentElement) {
      return window;
    }
    const overflowYVal = getComputedStyle(offsetParent, null).getPropertyValue('overflow-y');
    const overflowXVal = getComputedStyle(offsetParent, null).getPropertyValue('overflow-x');
    if (overflowXVal === 'auto' || overflowXVal === 'scroll' || overflowYVal === 'auto' || overflowYVal === 'scroll') {
      return offsetParent;
    }
  }
  return window;
};
